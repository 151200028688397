import { Lock } from '@material-ui/icons';
import React from 'react';
import { Field } from 'react-final-form';
import ToggleField from '../../../../../../../../../../../components/ToggleField';
import ModuleOption from '../../../ModuleOptions';
import './styles.scss';

const PersonalDetailsFormFields = () => {
  const baseClass = 'ickyc-personal-details-form-fields';

  return (
    <ModuleOption title="Form Fields">
      <div className={baseClass}>
        <div className={`${baseClass}__controll`}>Show Field</div>
        <div className={`${baseClass}__controll`}>Require Field</div>
        <div>
          <b>Given Name</b>
        </div>
        <div className={`${baseClass}__lock-icon-container`}>
          <Lock />
        </div>
        <div className={`${baseClass}__lock-icon-container`}>
          <Lock />
        </div>
        <div>
          <b>Middle Name</b>
        </div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[1].details.middleName.shown" />
        </div>
        <div className={`${baseClass}__controll`} />
        <div>
          <b>Surname</b>
        </div>
        <div className={`${baseClass}__lock-icon-container`}>
          <Lock />
        </div>
        <div className={`${baseClass}__lock-icon-container`}>
          <Lock />
        </div>
        <div>
          <b>Date of Birth</b>
        </div>
        <div className={`${baseClass}__lock-icon-container`}>
          {/* <Field component={ToggleField} name="modules[1].details.dateOfBirth.shown" /> */}
          <Lock />
        </div>
        <div className={`${baseClass}__lock-icon-container`}>
          {/* <Field component={ToggleField} name="modules[1].details.dateOfBirth.required" /> */}
          <Lock />
        </div>
        <div>
          <b>Nationality</b>
        </div>
        <div className={`${baseClass}__lock-icon-container`}>
          <Lock />
        </div>
        <div className={`${baseClass}__lock-icon-container`}>
          <Lock />
        </div>
        {/* <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[1].details.nationality.shown" />
        </div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[1].details.nationality.required" />
        </div> */}
        <div>
          <b>Email Address</b>
        </div>
        <div className={`${baseClass}__lock-icon-container`}>
          {/* <Field component={ToggleField} name="modules[1].details.emailAddress.shown" /> */}
          <Lock />
        </div>
        <div className={`${baseClass}__lock-icon-container`}>
          {/* <Field component={ToggleField} name="modules[1].details.emailAddress.required" /> */}
          <Lock />
        </div>
        <div className={`${baseClass}__controll-right`}>
          <Field
            component={ToggleField}
            name="modules[1].details.email2fa"
            label="Verify with Two Factor Authentication"
          />
        </div>

        <div>
          <b>Phone Number</b>
        </div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[1].details.phoneNumber.shown" />
        </div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[1].details.phoneNumber.required" />
        </div>
        <div className={`${baseClass}__controll-right`}>
          <Field name={`modules[1].details.geolocation`} component={ToggleField} label="Request Location Access" />
        </div>
      </div>
    </ModuleOption>
  );
};
export default PersonalDetailsFormFields;
