import React from 'react';
import './styles.scss';
const GoogleMap = ({ longitude, latitude }) => {
  return (
    <iframe
      className="ickyc-google-map"
      loading="lazy"
      title="Google Maps Location"
      allowFullScreen
      referrerpolicy="no-referrer-when-downgrade"
      src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${latitude},${longitude}`}
    />
  );
};
export default GoogleMap;
