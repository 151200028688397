import React, { useEffect } from 'react';
import './styles.scss';
const GoogleMap = ({ longitude, latitude }) => {
  // TODO: DELETE AFTER INTEGRATION TESTING
  useEffect(() => {
    const fetchGeocodeData = async () => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
        );
        const data = await response.json();
        console.log(data);
      } catch (error) {
        console.error('Error fetching geocode data:', error);
      }
    };

    fetchGeocodeData();
  });
  return (
    <iframe
      className="ickyc-google-map"
      loading="lazy"
      title="Google Maps Location"
      allowFullScreen
      referrerpolicy="no-referrer-when-downgrade"
      src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${latitude},${longitude}`}
    />
  );
};
export default GoogleMap;
