import GoogleMap from 'components/GoogleMap';
import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { UAParser } from 'ua-parser-js';
import api from '../../../../../../../../api';
import IComplyForm from '../../../../../../../../components/Form/IComplyForm';
import Spinner from '../../../../../../../../components/Spinner';
import bus from '../../../../../../../../modules/bus';
import enums from '../../../../../../../../utilities/enums';
import DateManager from '../../../../../../../../utilities/services/DateManager';
import { EntityContext, HistoryRecordContext } from '../../../../../../../../utilities/services/contexts';
import HistoryRecordControls from '../../../../../components/HistoryRecordControls';
import IssueList from '../../../../../components/IssueList';
import NaturalPersonInformationForm from '../../../../../components/NaturalPersonformationForm';
import './styles.scss';
import { makeFirstLetterUppercase } from './utils';

/**
 * Displays the natural information in immutable mode
 * @param {object} data - displayed data
 * @param {function} onDataFetch - caches once fetched data
 */

const PersonalInformationPreview = ({ data, onDataFetch }) => {
  const { personalInformation } = data;
  const { geoLocation, userAgent } = personalInformation || {};
  const [isLoading, setIsLoading] = useState(true);
  const [controlsInfo, setControlsInfo] = useState({});
  const { entityId, updateEntityInfo } = useContext(EntityContext);
  const { eventHistoryId } = useContext(HistoryRecordContext);
  const { browser, device, os } = UAParser(userAgent);

  useEffect(() => {
    if (JSON.stringify(data) !== '{}') {
      setIsLoading(false);
      return;
    }
    const fetchInfo = async () => {
      try {
        setIsLoading(true);
        const { data: response } = await api.kyc.entityManagement.naturalPerson.getInformationTableRecord(
          eventHistoryId,
          entityId,
        );
        const {
          status,
          sourceType,
          sourceDetail,
          eventId: evId,
          portalConfigurationId,
          portalDomain,
          portalName,
          ...rest
        } = response;
        setControlsInfo({
          status,
          sourceType,
          sourceDetail,
          eventId: evId,
          portalConfigurationId,
          portalDomain,
          portalName,
        });

        onDataFetch({ personalInformation: { ...rest, birthDate: DateManager.toDate(rest.birthDate) } });
      } catch (err) {
        onDataFetch({ message: `Error Loading History Record: ${eventHistoryId}` });
      } finally {
        setIsLoading(false);
      }
    };

    fetchInfo();
  }, [data, eventHistoryId, onDataFetch, entityId]);
  const { issues } = useContext(HistoryRecordContext);

  const handleStatusChange = useCallback(
    (issuesChange, issuesList, status, allRejected) => {
      const { personalInformation } = data;

      if (status === enums.HISTORY_EVENT_STATUSES.ACCEPTED) {
        bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
          type: 'kyc',
          section: enums.ACCORDION_INDEXES.PERSONAL_INFORMATION,
          issuesChange: -issues,
          allRejected,
        });
      } else {
        bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
          type: 'kyc',
          section: enums.ACCORDION_INDEXES.PERSONAL_INFORMATION,
          issuesChange,
          allRejected,
        });
      }
      updateEntityInfo(personalInformation);
      onDataFetch({ ...data, personalInformation: { ...data.personalInformation, issues: issuesList } });
    },
    [onDataFetch, data, updateEntityInfo, issues],
  );

  const classBase = 'ickyc-personal-info-record';

  const initialValues = useMemo(
    () => ({
      ...data,
      userAgent: {
        osName: os.name,
        osVersion: os.version,
        deviceType: makeFirstLetterUppercase(device.type) || 'Desktop',
        deviceVendor: device.vendor,
        deviceModel: device.model,
        browserName: browser.name,
        browserVersion: browser.version,
        browserType: browser.type,
      },
    }),
    [data, browser, device, os],
  );

  if (isLoading) {
    return (
      <div className={classBase}>
        <Spinner />
      </div>
    );
  }
  return (
    <div className={classBase}>
      {data.message ? (
        <div className={`${classBase}__message`}>{data.message}</div>
      ) : (
        <>
          <div className={classBase}>
            <div className={`${classBase}__full`}>
              <IComplyForm initialValues={initialValues} onSubmit={() => {}}>
                <IssueList list={data?.personalInformation.issues || []} />
                <NaturalPersonInformationForm preview checkList={data?.personalInformation?.checkList} />
                <div className={`${classBase}__user-device-data-box-box`}>
                  <label>Device Info & Geolocation</label>
                  <div className={`${classBase}__user-device-data-box`}>
                    <div className={`${classBase}__user-device-data`}>
                      <div>
                        <Field component={InputField} name="personalInformation.ipAddress" label="IP Address" preview />

                        <Field component={InputField} name="userAgent.osName" label="OS Name" preview />
                        <Field component={InputField} name="userAgent.osVersion" label="OS Version" preview />
                        <Field component={InputField} name="userAgent.deviceType" label="Device Type" preview />
                        <Field component={InputField} name="userAgent.deviceVendor" label="Device Vendor" preview />
                        <Field component={InputField} name="userAgent.deviceModel" label="Device Model" preview />
                      </div>
                      <div>
                        <Field component={InputField} name="userAgent.browserName" label="Browser Name" preview />
                        <Field component={InputField} name="userAgent.browserVersion" label="Browser Version" preview />
                        <Field component={InputField} name="userAgent.browserType" label="Browser Type" preview />
                        <Field component={InputField} name="geoCodingCity" label="City" preview />
                        <Field component={InputField} name="geoCodingState" label="State" preview />
                        <Field component={InputField} name="geoCodingCountry" label="Country" preview />
                      </div>
                    </div>
                    <GoogleMap {...geoLocation} />
                  </div>
                </div>
              </IComplyForm>
            </div>
          </div>
          <HistoryRecordControls info={controlsInfo} onStatusChange={handleStatusChange} />
        </>
      )}
    </div>
  );
};

PersonalInformationPreview.propTypes = {
  data: PropTypes.shape({
    personalInformation: PropTypes.shape({ issues: PropTypes.arrayOf(PropTypes.string), checkList: PropTypes.shape() }),
    message: PropTypes.string,
  }),
  onDataFetch: PropTypes.func,
};

PersonalInformationPreview.defaultProps = {
  data: {},
  onDataFetch: () => {},
};

export default PersonalInformationPreview;
